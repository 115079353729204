@import 'variables'
@import 'header'
@import 'footer'

html
	height: 100%
	font-family: ui-rounded, sans-serif

body
	margin: 0
	min-height: 100%
	display: flex
	flex-direction: column

p
	line-height: 150%

a
	transition: color 0.2s
	text-decoration: none
	color: royalblue

	@media(hover: hover)
		&:hover
			color: lightskyblue

h1
	border-bottom: 1px solid text

.readable
	@extend %readable-base

.center-row
	@extend %center-row-base

.center-column
	@extend %center-column-base

.center-text
	text-align: center

#content
	display: flex
	flex-direction: column
	flex-grow: 1
	margin: 15px $page-padding-horizontal
