$max-mobile-width: 766px
$page-padding-horizontal: 5%

\:root
	color-scheme: light dark
	--syntax-background: #eeeeee
	--syntax-error-background: #e50003
	--syntax-punctuation: #191919
	--syntax-comment: #a8a8a8
	--syntax-keyword: #ad44dd
	--syntax-name-0: #4173e0
	--syntax-name-1: #38a5d1
	--syntax-operator: #ed0e5f
	--syntax-literal-number: #6c2be5
	--syntax-literal-string: #b78c00

	@media (prefers-color-scheme: dark)
		--syntax-background: #303030
		--syntax-error-background: #bc0003
		--syntax-punctuation: #f8f8f2
		--syntax-comment: #75715e
		--syntax-name-0: #a6e22e
		--syntax-name-1: #66d9ef
		--syntax-operator: #f92672
		--syntax-literal-number: #ae81ff
		--syntax-literal-string: #e6db74

%readable-base
	display: flex
	flex-direction: column
	width: 100%
	max-width: 700px

%center-row-base
	display: flex
	justify-content: center

%center-column-base
	display: flex
	flex-direction: column
	align-items: center
