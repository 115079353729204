footer
	display: flex
	justify-content: space-between
	align-items: center
	padding: 20px $page-padding-horizontal
	border-top: 1px solid text

	@media (max-width: $max-mobile-width)
		flex-direction: column-reverse

	svg
		fill: text

		@media(hover: hover)
			&:hover
				fill: gray

	a
		display: flex
		flex-shrink: 1

	p
		font-size: 12px
		text-align: center
